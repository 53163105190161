<template>
    <div :style="`direction:${lang.dir}`">
        <v-form
            ref="crmForInv"
            lazy-validation
        >
        <b-sidebar backdrop width="80rem" id="customer_crm" aria-labelledby="sidebar-no-header-title" no-header :style="`direction:`+lang.ldir" right>
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.view_customer}}</span>
                    </div>
                    <div @click="hide" id="HideMeCRM" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="myBtn backBlack" :style="`direction:${lang.dir}`">
                    <!-- <div class="btnRow c_darkcyan">{{ lang.add_car }}</div> -->
                    <div class="btnRow c_green" @click="InvOP()" v-b-toggle.add_invoice>{{ lang.add_invocie }}</div>
                    <div class="btnRow c_darkslategrey" @click="resetAllValues()" v-b-toggle.add_new_card>{{ lang.add_new_card }}</div>
                    <div class="btnRow c_darkblue" @click="addQuot()" v-b-toggle.add_quotation>{{ lang.add_quotation }}</div>
                    <div class="btnRow c_darkolivegreen" @click="addTask()" v-b-toggle.add_addClientTask>{{ lang.add_task }}</div>
                    <div class="btnRow c_darkorange" @click="addCalendar()" v-b-toggle.add_addbooking>{{lang.add_booking}}</div>
                    <div class="btnRow c_red" @click="addNote()" v-b-toggle.add_crmnote>{{ lang.add_notes }}</div>
                    <div class="btnRow c_darkslateblue">{{ lang.add_fincance_request }}</div>
                    <div class="btnRow c_darkorchid" @click="addTask()" v-b-toggle.add_addClientWarranty>{{lang.add_warantly}}</div>
                    <div class="btnRow c_blue" @click="aupdate()" v-b-toggle.update_customer>{{ lang.update }}</div>
                </div>
                <div class="mySubBtn" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="btnRowF c_black" id="c_cards" @click="Cards('c_cards')">{{ lang.maintenance_cards }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_invoices" @click="Invoices('c_invoices')">{{ lang.invoices }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_payments" @click="Payments('c_payments')">{{ lang.payments }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_quotations" @click="Quots('c_quotations')">{{ lang.quotations }} <i class="ms-1 fas fa-arrow-down"></i></div>
                </div>
                <div class="myBtn" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.customer_info }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.customerid }}</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.customerid }}</td>
                                    <th class="text-center">{{ lang.full_name }}</th>
                                    <td class="text-center" colspan="3">{{ customer.full_name }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{ lang.customer_type }}</th>
                                    <td class="text-center" :style="`${bgColor}`">{{ _custoemr_type }}</td>
                                    <th class="text-center" style="min-width:50px;">{{ lang.mobile }}</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.mobile }}</td>
                                    <th class="text-center" style="min-width:50px;">{{ lang.mobile }} 2</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.mobile2 }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center">{{ lang.company_name }}</th>
                                    <td class="text-center" colspan="5">{{ customer.company_name }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center">{{ lang.vat_number }}</th>
                                    <td class="text-center" colspan="2">{{ customer.vatid }}</td>
                                    <th class="text-center">{{ lang.crt_number }}</th>
                                    <td class="text-center" colspan="2">{{ customer.crt_number }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center">{{ lang.address }}</th>
                                    <td class="text-center" colspan="5">{{ customer.address }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 3">
                                    <th class="text-center">{{ lang.entity_name }}</th>
                                    <td class="text-center" colspan="2">{{ customer.entity_name }}</td>
                                    <th class="text-center">{{ lang.branch_name }}</th>
                                    <td class="text-center" colspan="2">{{ customer.branch_name }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{lang.customer_status}}</th>
                                    <td class="text-center" colspan="2">
                                        <b-form-select class="selborder"
                                            v-model="status" @change="changeStatus()"
                                            :options="cusStatus"
                                        />
                                    </td>
                                </tr>
                            </thead>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.finance_report }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="width:25%;">{{ lang.cars }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.invoices }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.maintenance_cards }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.quotations }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.cars }}</td>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.invoices }}</td>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.cards }}</td>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.quotations }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <br />
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="width:25%;">{{ lang.invoice_total }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.unpaid_job_orders }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.paid_amount }}</th>
                                    <th class="text-center" style="width:25%;">{{ lang.remain }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px !important;" class="text-center">{{ $convertToComa(finance.invoices,$store.state.licenseType.moneyComma) }}</td>
                                    <td style="height: 30px !important;" class="text-center">{{ $convertToComa(finance.cards,$store.state.licenseType.moneyComma) }}</td>
                                    <td style="height: 30px !important;border:1px solid #000 !important" class="text-center backGreen">{{ $convertToComa(finance.paid,$store.state.licenseType.moneyComma) }}</td>
                                    <td style="height: 30px !important;border:1px solid #000 !important" class="text-center backRed">{{ $convertToComa(finance.remain,$store.state.licenseType.moneyComma) }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.cars }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.plate_number }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.model }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.car_year }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.car_color }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.vin_number }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.invoices }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.unpaid_job_orders }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in cars" :key="index">
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.model }}</td>
                                    <td class="text-center">{{ item.car_year }}</td>
                                    <td class="text-center">{{ item.color }}</td>
                                    <td class="text-center">{{ item.vin_number }}</td>
                                    <td class="text-center">{{ item.invoices }}</td>
                                    <td class="text-center">{{ item.cards }}</td>
                                    <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.report }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.invoices }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.invoiceno }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.inv_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_time }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.invoice_type }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.total }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.vat }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.paid_amount }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.remain }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="width:50px;">{{ lang.make_payment }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in invoices" :key="index">
                                    <td class="text-center">{{ item.invoice_number }}</td>
                                    <td class="text-center">{{ item.inv_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.inv_date.substring(11) }}</td>
                                    <td class="text-center">{{ item[`_invtype_`+lang.langname] }}</td>
                                    <td class="text-center">{{ $convertToComa(item.total,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.vat,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.paid,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.remain,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor};color:${item.color};border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.make_payment }}</div>
                                        <div v-else></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backBlack text-center">{{ lang.total }}</td>
                                <td class="backGreen text-center">{{ totlas.total }}</td>
                                <td class="backRed text-center">{{ totlas.vat }}</td>
                                <td class="backGreen text-center">{{ totlas.ftotal }}</td>
                                <td class="backGreen text-center">{{ totlas.paid }}</td>
                                <td class="backRed text-center">{{ totlas.remain }}</td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                            </tfoot>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.mentanace_card }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.cardid }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.received }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.delivered }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.total }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.vat }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.paid_amount }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.remain }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="width:50px;">{{ lang.make_payment }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in cards" :key="index">
                                    <td class="text-center">{{ item.card_id }}</td>
                                    <td class="text-center">{{ item.received_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.delivery_date != '' ? item.delivery_date.substring(0,10) : '-' }}</td>
                                    <td class="text-center">{{ $convertToComa(item.total,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.vat,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.paid,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.remain,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor};color:${item.color};border:2px solid #FFF !important;`">{{ item._status }}</td>
                                    <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.make_payment }}</div>
                                        <div v-else></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backBlack text-center">{{ lang.total }}</td>
                                <td class="backGreen text-center">{{ ctotlas.total }}</td>
                                <td class="backRed text-center">{{ ctotlas.vat }}</td>
                                <td class="backGreen text-center">{{ ctotlas.ftotal }}</td>
                                <td class="backGreen text-center">{{ ctotlas.paid }}</td>
                                <td class="backRed text-center">{{ ctotlas.remain }}</td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                            </tfoot>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.quotations }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.quotation_no }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.quot_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in quotes" :key="index">
                                    <td class="text-center">{{ item.quot_number }}</td>
                                    <td class="text-center">{{ item.quot_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor};color:${item.color};border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.view }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.financial_request }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.invoiceno }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.amount }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.print }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in fineReq" :key="index">
                                    <td class="text-center">{{ item.invoice_number }}</td>
                                    <td class="text-center">{{ item.created_date }}</td>
                                    <td class="text-center">{{ item.amount }}</td>
                                    <td class="text-center">{{ item.created_by }}</td>
                                    <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.print }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.notes }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.notes }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in notes" :key="index">
                                    <td class="text-center">{{ item.created_date }}</td>
                                    <td class="text-start" style="white-space: break-spaces;">{{ item.notes }}</td>
                                    <td class="text-center">{{ item.created_by }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.payments }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">{{ lang.voutcher_no }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.print }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in payments" :key="index">
                                    <td class="text-center">{{ item.voutcherid }}</td>
                                    <td class="text-center">{{ item.receipt_date }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.print }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.tasks }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">#</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.dead_line }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="width:50px;">{{ lang.view }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in tasks" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.deadline.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.add_warantly }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">#</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.plate_number }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.dead_line }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="width:50px;">{{ lang.view }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in warrantly" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.deadline.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.book_calendar }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">#</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.start_time }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.dead_line }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.deadline_time }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center" style="width:50px;">{{ lang.view }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in calendar" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.start_time }}</td>
                                    <td class="text-center">{{ item.deadline.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.deadline_time }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    
                </div>
            </template>
        </b-sidebar>
        </v-form>
        <UpdateCustomer ref="updatecustomer" />
        <addClientNote ref="clientNotes" />
        <addInvoice ref="addinvoices" />
        <AddQuotation ref="AddQuotation" />
        <addNewCard ref="createnew" />
        <addClientTask ref="add_addClientTask" />
        <addClientWarranty ref="add_addClientWarranty" />
        <addCalendar ref="addCalendar" />
    </div>
</template>

<script>
import axios from 'axios'
import addClientNote from '@/components/addClientNotes.vue'
import addInvoice from '@/components/addInvoice.vue'
import AddQuotation from '@/components/addQuotation.vue'
import addNewCard from "@/components/addNewCard.vue"
import UpdateCustomer from '@/components/update-customer.vue'
import addClientTask from '@/components/addClientTask.vue'
import addClientWarranty from '@/components/addClientWarranty.vue'
import addCalendar from '@/components/addCalendar.vue'
export default {
    components: {addClientNote,addInvoice,AddQuotation,addNewCard,UpdateCustomer,addClientTask,addClientWarranty,addCalendar},
    data() {
        return {
            customerid: 0,
            tasks: [],
            fineReq: [
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
            ],
            notes: [],
            quotes:[],
            cards:[],
            invoices: [],
            cars:[],
            payments: [],
            statistic: {
                cars: 0,
                invoices: 0,
                cards: 0,
                quotations: 0,
            },
            finance: {
                invoices: 0,
                cards: 0,
                paid: 0,
                remain: 0
            },
            customer: {
            },
            warrantly: [],
            cusStatus: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        totlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.invoices.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.invoices[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.invoices[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.invoices[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.invoices[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.invoices[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        ctotlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.cards.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.cards[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.cards[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.cards[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.cards[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.cards[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        _custoemr_type: function(){
            let t = this.lang.individual_client
            if(this.customer.customer_type == 2){
                t = this.lang.company_clinet
            }
            if(this.customer.customer_type == 3){
                t = this.lang.government_clinet
            }
            return t;
        },
        bgColor: function(){
            let t = 'background: #335801 !important;color:#fff !important'
            if(this.customer.customer_type == 2){
                t = 'background: darkblue !important;color:#fff !important'
            }
            if(this.customer.customer_type == 3){
                t = 'background: #404258 !important;color:#fff !important'
            }
            return t;
        },

    },
    methods:{
        aupdate(){
            console.log(this.customer);
            this.$refs.updatecustomer.customers = {
                id: this.customer.id,
                customerid: this.customer.customerid,
                mobile: this.customer.mobile,
                mobile1: typeof this.customer !== 'undefined' ? this.customer.mobile2 : '',
                full_name: this.customer.full_name,
                company_name: this.customer.company_name,
                company_vatid: this.customer.company_vatid,
                building_no: this.customer.building_no,
                street_name: this.customer.street_name,
                branch_number: this.customer.branch_number,
                district: this.customer.district,
                zipcode: this.customer.zipcode,
                city:this.customer.city,
                address:this.customer.address,
                customer_type: this.customer.customer_type,
                entity_name: this.customer.entity_name,
                branch_name: this.customer.branch_name,
                // crt_number: ''
            }
        },
        resetAllValues(){
            this.$refs.createnew.resetValues();
            this.$refs.createnew.getInvSettings();
            this.$refs.createnew.$refs.firstCar.image = '';
            this.$refs.createnew.customer.customerid = this.customerid;
            this.$refs.createnew.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.createnew.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.createnew.changeCusType(2);
            }
            else{
                this.$refs.createnew.changeCusType(0);
            }
            this.$refs.createnew.getName();
        },
        addQuot(){
            this.$refs.AddQuotation.reset();
            this.$refs.AddQuotation.customer.mobile = this.customer.mobile;
            this.$refs.AddQuotation.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.AddQuotation.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.AddQuotation.changeCusType(2);
            }
            else{
                this.$refs.AddQuotation.changeCusType(0);
            }
            this.$refs.AddQuotation.getName();
        },
        InvOP(){
            // console.log(this.customerid);

            this.$refs.addinvoices.reset();
            this.$refs.addinvoices.customer.customerid = this.customerid;
            this.$refs.addinvoices.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.addinvoices.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.addinvoices.changeCusType(2);
            }
            else{
                this.$refs.addinvoices.changeCusType(0);
            }
            this.$refs.addinvoices.getName();
        },
        addCalendar(){

            this.$refs.addCalendar.task_description = "";
            this.$refs.addCalendar.customerid = this.customerid;
        },
        addTask(){

            this.$refs.add_addClientTask.task_description = "";
            this.$refs.add_addClientWarranty.task_description = "";
            this.$refs.add_addClientTask.customerid = this.customerid;
            this.$refs.add_addClientWarranty.customerid = this.customerid;
        },
        addNote(){

            this.$refs.clientNotes.notes = '';
            this.$refs.clientNotes.customerid = this.customerid;
        },
        getCurrentCards(){
            this.getCustomerInfo()
        },
        getClients(){
            this.getCustomerInfo()
        },
        async getCustomerInfo()
        {
            if(this.cusotmerid == 0){
                return false;
            }
            let post = new FormData();
            post.append('type' , 'getCustomerInfo');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('auth',auth);
            post.append('data[customerid]',this.customerid)
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            );
            if(response && response.data){
                const cus = response.data.results.data.customer;
                const inv = response.data.results.data.invoices;
                const receipt = response.data.results.data.receipt;
                const cards = response.data.results.data.cards;
                const cars = response.data.results.data.cars;
                const quotes = response.data.results.data.quotes;
                const notes = response.data.results.data.notes;
                const tasks = response.data.results.data.tasks;
                const warrantly = response.data.results.data.warrantly;
                const calendar = response.data.results.data.calendar;
                this.customer ={
                    id: cus.id,
                    customerid: cus.customerid,
                    mobile: cus.mobile,
                    mobile2: cus.mobile2,
                    customer_type: cus.customer_type,
                    full_name: cus.full_name,
                    company_name: cus.company_name,
                    crt_number: cus.crt_number,
                    vatid: cus.company_vatid,
                    address: cus.address,
                    entity_name: cus.entity_name,
                    branch_name: cus.branch_name,
                }
                this.invoices = inv;
                this.payments = receipt;
                this.cards = cards;
                this.cars = cars;
                this.quotes = quotes;
                this.notes = notes;
                this.tasks = tasks;
                this.warrantly = warrantly;
                this.calendar = calendar;
                this.statistic.invoices = inv.length;
                this.statistic.cards = cards.length;
                this.statistic.cars = cars.length;
                this.statistic.quotations = quotes.length;
                this.finance.invoices = 0;
                this.finance.paid = 0;
                for(let i=0;i<inv.length;i++){
                    this.finance.invoices = parseFloat(this.finance.invoices) + parseFloat(inv[i].ftotal);
                    this.finance.paid = parseFloat(this.finance.paid) + parseFloat(inv[i].paid);
                }
                this.finance.cards = 0;
                for(let i=0;i<cards.length;i++){
                    this.finance.cards = parseFloat(this.finance.cards) + parseFloat(cards[i].remain);
                }
                this.finance.remain = parseFloat(this.finance.invoices) - parseFloat(this.finance.paid)
                this.finance.remain = parseFloat(this.finance.remain) + parseFloat(this.finance.cards)
            }
        },
        Cards(id){
            this.getIt(id);
        },
        Invoices(id){
            this.getIt(id);
        },
        Payments(id){
            this.getIt(id);
        },
        Quots(id){
            this.getIt(id);
        },
        getIt(id){
            const btns = [
                'c_cards', 'c_invoices','c_payments','c_quotations'
            ];
            for(let i=0;i< btns.length;i++){
                if(btns[i] == id){
                    document.getElementById(btns[i]).classList.remove('c_lightgray');
                    document.getElementById(btns[i]).classList.add('c_black');
                }else{
                    document.getElementById(btns[i]).classList.remove('c_black');
                    document.getElementById(btns[i]).classList.add('c_lightgray');
                }
            }
            

        },
        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.cusStatus.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
    },
    created(){
        this.getStatus('customerStatus');
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>